// Here you can add other styles
.sidebar-nav {
	font-size: 12px;
}
.animated {
  min-height: 600px;
}
.animated .loader {
  width: 60px;
}
.mandatory-field {
  color: red;
  font-size: 12px;
}
.captcha-container {
  margin-bottom: 20px;
  padding: 20px 15px 15px 15px;
  box-shadow: rgb(0 0 0 / 18%) 0px 4px 12px;
  .captcha-code-block {
    display: flex;
    margin-bottom: 5px;
    .captcha-code {
      background-image: linear-gradient(#000, red);
      color: #ffffff;
      padding: 5px 20px;
      margin-right: 5px;
    }
    .captcha-refresh {
      cursor: pointer;
      background: blue;
      color: #ffffff;
      padding: 5px 10px;
      margin-right: 5px;
    }
  }
  .captcha-input input {
    margin-bottom: 10px;
  }
}

.anchor-link {
  color: blue !important;
  cursor: pointer;
  text-decoration: underline;
}